import React, { useState, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';

const initialState = {
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    phoneNumber: "",
    duty: "",
    designation: "",
    nationalty: "",
    address: "",
    date_debut_consultant: "",
    socialSecurityNumber: "",
};

const validationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ERRORS':
            return action.errors;
        case 'CLEAR_ERRORS':
            return {};
        default:
            return state;
    }
};

const AddEmployeeModal = ({ isOpen, toggle, handleAdd }) => {
    const [employeeData, setEmployeeData] = useState(initialState);
    const [validationErrors, dispatch] = useReducer(validationReducer, {});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRadioChange = (e) => {
        setEmployeeData((prevData) => ({
            ...prevData,
            designation: e.target.value,
        }));
    };

    const validateFields = () => {
        const errors = {};

        // Required field validation
        Object.entries(employeeData).forEach(([key, value]) => {
            if (!value.trim()) {
                errors[key] = "Ce champ est obligatoire.";
            }
        });

        // Specific field validations
        if (employeeData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(employeeData.email)) {
            errors.email = "Adresse email non valide.";
        }

        if (employeeData.phoneNumber && !/^\+?[0-9]{10,15}$/.test(employeeData.phoneNumber)) {
            errors.phoneNumber = "Numéro de téléphone non valide.";
        }

        return errors;
    };

    const handleSubmit = () => {
        setIsSubmitted(true);
        const errors = validateFields();

        if (Object.keys(errors).length > 0) {
            dispatch({ type: 'SET_ERRORS', errors });
            return;
        }

        dispatch({ type: 'CLEAR_ERRORS' });
        handleAdd(employeeData);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            <ModalHeader toggle={toggle}>Ajouter un Employé</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="12" className="mb-3">
                        <FormGroup>
                            <Label>Désignation:</Label>
                            <div>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="designation"
                                            value="M."
                                            checked={employeeData.designation === "M."}
                                            onChange={handleRadioChange}
                                        />{' '}
                                        Monsieur
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="designation"
                                            value="Mme"
                                            checked={employeeData.designation === "Mme"}
                                            onChange={handleRadioChange}
                                        />{' '}
                                        Madame
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>
                    </Col>
                    {[
                        { label: "Prénom", name: "firstName", type: "text" },
                        { label: "Nom", name: "lastName", type: "text" },
                        { label: "Date de Naissance", name: "birthDate", type: "date" },
                        { label: "Email", name: "email", type: "email" },
                        { label: "Téléphone", name: "phoneNumber", type: "text" },
                        { label: "Fonction", name: "duty", type: "text" },
                        { label: "Nationalité", name: "nationalty", type: "text" },
                        { label: "Adresse", name: "address", type: "text" },
                        { label: "Numéro de Sécurité Sociale", name: "socialSecurityNumber", type: "text" },
                        { label: "Date de début consultant", name: "date_debut_consultant", type: "date" }
                    ].map(({ label, name, type }) => (
                        <Col md="6" key={name} className="mb-3">
                            <FormGroup>
                                <Label for={name}>{label}:</Label>
                                <Input
                                    type={type}
                                    name={name}
                                    id={name}
                                    value={employeeData[name]}
                                    onChange={handleInputChange}
                                    invalid={isSubmitted && !!validationErrors[name]}
                                />
                                {isSubmitted && validationErrors[name] && (
                                    <FormText color="danger">{validationErrors[name]}</FormText>
                                )}
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Annuler
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Ajouter
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddEmployeeModal;