import React, {useState, useEffect} from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Form,
    Table,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Widget from "../../components/Widget/Widget";
import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../../actions/loading";
import {generateFactureForCra, getCraManger, UpdateCraManger} from "../../services/configService";
import {getDayOfWeek, getWeekNumber, isWeekend} from "../cra/functions";
import showToast from "../../components/NotificationToast/toastUtils";
import {AiOutlineFilePdf} from "react-icons/ai";

const CraManagement = () => {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const [message, setMessage] = useState("");
    // States
    const [craData, setCRAData] = useState([]);
    const [selectedCRA, setSelectedCRA] = useState(null);
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [showModal, setShowModal] = useState(false);
    const [craRecords, setCraRecords] = useState([]);
    const [totalServiceDays, setTotalServiceDays] = useState(0);
    const statusFormatter = (cell) => {
        let badgeColor;
        let label;
        switch (cell) {
            case 1:
                badgeColor = "warning";
                label = "En attente C";
                break;
            case 2:
                badgeColor = "warning";
                label = "En attente";
                break;
            case 3:
                badgeColor = "success";
                label = "Traité";
                break;
            case 4:
                badgeColor = "success";
                label = "Traité";
                break;
            case 5:
                badgeColor = "success";
                label = "Traité";
                break;
            default:
                badgeColor = "secondary";
                label = "Pas statut";
        }

        return <span className={`badge bg-${badgeColor}`}>{label}</span>;
    };
    const columns = [
        {dataField: "craId", text: "ID", sort: true},
        {dataField: "consultantName", text: "Consultant", sort: true},
        {dataField: "client", text: "Client", sort: true},
        {dataField: "month", text: "Mois", sort: true},
        {dataField: "year", text: "Année", sort: true},
        {dataField: "totalServiceDays", text: "Nbr jours", sort: true},
        {dataField: "status", text: "Statut", sort: true, formatter: statusFormatter},
        {
            dataField: "generateInvoice", text: "Facture", formatter: (cell, row) => (
                (!row.factureGenerated && (row.status === 3 || row.status === 4)) ?  <Button color="secondary" onClick={(e) => {
                    e.preventDefault();
                    handleGenerateInvoice(row, e);
                }}>
                    <AiOutlineFilePdf style={{fontSize: '28px'}}/>
                </Button> : <span/>
            ),
        }
    ];

    const fetchCRAData = async () => {
        dispatch(startLoading());
        try {
            const res = await getCraManger(month, year);
            setCRAData(res.data || []);
        } catch (error) {
            console.error("Error fetching CRA data:", error);
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        fetchCRAData();
    }, [month, year, dispatch]);

    const handleRowClick = (row) => {
        setSelectedCRA(row);
        setShowModal(true);
    };

    const prepareCraRecords = (days) => {
        const daysInMonth = new Date(year, month, 0).getDate();

        return Array.from({length: daysInMonth}, (_, day) => {
            const date = new Date(year, month - 1, day + 1);

            // Vérifie si la date générée est valide
            if (isNaN(date.getTime())) {
                console.error("Date générée invalide:", date);
                return {date: null, serviceDay: 0}; // Valeur par défaut si la date est invalide
            }

            // Cherche l’entrée de `days` correspondant au jour en utilisant l'index `day`
            const dayData = Array.isArray(days) && days[day]
                ? days[day]
                : null;

            // Vérifie que `dayData` est valide et extrait `serviceDay` uniquement si elle existe
            const serviceDay = dayData && typeof dayData.serviceDay === 'number'
                ? dayData.serviceDay
                : 0;

            return {
                date: date.toISOString().split('T')[0],
                serviceDay,
            };
        });
    };


    useEffect(() => {
        if (selectedCRA) {
            const records = prepareCraRecords(selectedCRA.days || []);
            setCraRecords(records);
            setTotalServiceDays(records.reduce((sum, record) => sum + record.serviceDay, 0));
        }
    }, [selectedCRA]);

    const toggleModal = () => {
        setShowModal(prev => !prev);
    };
    const handleReject = async () => {
        if (selectedCRA) {
            try {
                startLoading();
                await UpdateCraManger({
                    idRecord: selectedCRA.craId,
                    status: 1, // Status for rejection
                    message: message // Message input for rejection reason
                });
                setShowModal(false); // Close modal
                setMessage(""); // Reset message input
                await fetchCRAData(); // Reload data
                showToast("Les modifications sont effectuées");
            } catch (error) {
                showToast("Une erreur au moment de mofication", "error");
            } finally {
                stopLoading()
            }
        }
    };

    const handleValidate = async () => {
        if (selectedCRA) {
            try {
                startLoading();
                await UpdateCraManger({
                    idRecord: selectedCRA.craId,
                    status: 3, // Status for validation
                    message: "" // Empty message on validation
                });
                setShowModal(false); // Close modal
                setMessage(""); // Reset message input
                await fetchCRAData(); // Reload data
            } catch (error) {
                console.error("Error updating CRA record:", error);
            } finally {
                stopLoading()
            }
        }
    };
    const handleGenerateInvoice = async (row, e) => {
        e.stopPropagation();
        try {
            startLoading();
            const response = await generateFactureForCra(row.craId);
            if (response && response.data) {
                showToast("Facture générée avec succès.");
            }
        } catch (error) {
            showToast("Erreur lors de la génération de la facture.", "error");
        } finally {
        await fetchCRAData();
        stopLoading();
        }
    };

    console.log(craData)
    return (
        <Form>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <Label>Mois</Label>
                        <Input type="select" value={month} onChange={(e) => setMonth(Number(e.target.value))}>
                            {Array.from({length: 12}, (_, i) => (
                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Année</Label>
                        <Input type="select" value={year} onChange={(e) => setYear(Number(e.target.value))}>
                            {Array.from({length: 10}, (_, i) => 2024 + i).map(y => (
                                <option key={y} value={y}>{y}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <Widget>
                        <BootstrapTable
                            keyField="craId"
                            data={craData}
                            columns={columns}
                            pagination={paginationFactory({sizePerPage: 10, hideSizePerPage: true})}
                            hover
                            striped
                            condensed
                            rowEvents={{
                                onClick: (e, row) => handleRowClick(row),
                            }}
                        />
                    </Widget>
                </Col>
            </Row>

            <Modal isOpen={showModal} toggle={toggleModal} className="add-porspection">
                <ModalHeader toggle={toggleModal}>Détails CRA</ModalHeader>
                <ModalBody>

                    {craRecords.length > 0 ? (
                        <Row>
                            <Col>
                                <Table bordered className="cra-table">
                                    <thead>
                                    <tr>
                                        <th>Semaine</th>
                                        {craRecords.map((day, index) => (
                                            <th key={index}>{getWeekNumber(new Date(day.date))}</th>
                                        ))}
                                        <th>Total</th>
                                    </tr>
                                    <tr>
                                        <th>Jours</th>
                                        {craRecords.map((day, index) => (
                                            <th key={index}
                                                className={isWeekend(new Date(day.date)) ? 'weekend' : 'Notweekend'}>
                                                {new Date(day.date).getDate()}
                                            </th>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th></th>
                                        {craRecords.map((day, index) => (
                                            <th key={index}
                                                className={isWeekend(new Date(day.date)) ? 'weekend' : 'Notweekend'}>
                                                {getDayOfWeek(new Date(day.date))}
                                            </th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Jours prestés</td>
                                        {craRecords.map((day, index) => (
                                            <td key={index} className={isWeekend(new Date(day.date)) ? 'weekend' : ''}>
                                                <input
                                                    className="cra_input"
                                                    type="text"
                                                    value={day.serviceDay}
                                                    readOnly // Utilize readOnly instead of disabled for better accessibility
                                                />
                                            </td>
                                        ))}
                                        <td>{totalServiceDays}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <p>Aucun enregistrement disponible.</p>
                    )}
                    <Row className="d-flex justify-content-end">
                        <div className="button-container mb-1 mt-2 d-flex align-items-center">
                            <Input
                                className="mr-4"
                                type="text"
                                placeholder="Message"
                                maxLength="35" // Sets the maximum characters to 50
                                value={message} // Bind value to state
                                onChange={(e) => setMessage(e.target.value)} // Update state on change

                            />
                            <button className="button mr-2" onClick={handleValidate}>
                                <i className="eva eva-checkmark-outline icon"/>
                            </button>
                            <button className="button mr-2" onClick={handleReject}>
                                <i className="eva eva-close-outline icon"/>
                            </button>
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Fermer</Button>
                </ModalFooter>
            </Modal>
        </Form>
    );
};

export default CraManagement;
