import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import {updateEmployee} from "../../services/configService";
import showToast from "../../components/NotificationToast/toastUtils";

const EditEmployeeModal = ({ isOpen, toggle, selectedEmployee }) => {
    const [employeeData, setEmployeeData] = useState({
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        duty: "",
        nationalty: "",
        address: "",
        socialSecurityNumber: "",
        dateDebutConsultant: "",
        designation: ""
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (selectedEmployee) {
            setEmployeeData({
                firstName: selectedEmployee.firstName || "",
                lastName: selectedEmployee.lastName || "",
                birthDate: selectedEmployee.birthDate || "",
                email: selectedEmployee.email || "",
                phoneNumber: selectedEmployee.phoneNumber || "",
                duty: selectedEmployee.duty || "",
                nationalty: selectedEmployee.nationalty || "",
                address: selectedEmployee.address || "",
                socialSecurityNumber: selectedEmployee.socialSecurityNumber || "",
                dateDebutConsultant: selectedEmployee.dateDebutConsultant || "",
                picture: selectedEmployee.picture || "",
                designation: selectedEmployee.designation || ""
            });
        }
    }, [selectedEmployee]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData({
            ...employeeData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        try {
            await updateEmployee(selectedEmployee.id, employeeData);
            showToast("L'employé a été modifié avec succès.");
        } catch (error) {
            showToast("L'employé n'a pas pu être modifié. " + error.message, "error");
        }
        toggle();
    };
    const handleRadioChange = (e) => {
        setEmployeeData({
            ...employeeData,
            designation: e.target.value
        });
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Modifier un Employé</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Désignation:</Label>
                    <div>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="designation"
                                    value="M."
                                    checked={employeeData.designation === "M."}
                                    onChange={handleRadioChange}
                                />{' '}
                                Monsieur
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="designation"
                                    value="Mme"
                                    checked={employeeData.designation === "Mme"}
                                    onChange={handleRadioChange}
                                />{' '}
                                Madame
                            </Label>
                        </FormGroup>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="firstName">Prénom:</Label>
                    <Input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="Prénom"
                        value={employeeData.firstName}
                        onChange={handleChange}
                    />
                    {errors.firstName && <div className="error">{errors.firstName}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="lastName">Nom:</Label>
                    <Input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Nom"
                        value={employeeData.lastName}
                        onChange={handleChange}
                    />
                    {errors.lastName && <div className="error">{errors.lastName}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="birthDate">Date de Naissance:</Label>
                    <Input
                        type="date"
                        id="birthDate"
                        name="birthDate"
                        value={employeeData.birthDate}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="email">Email:</Label>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={employeeData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <div className="error">{errors.email}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="phoneNumber">Téléphone:</Label>
                    <Input
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Téléphone"
                        value={employeeData.phoneNumber}
                        onChange={handleChange}
                    />
                    {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="duty">Fonction:</Label>
                    <Input
                        type="text"
                        id="duty"
                        name="duty"
                        placeholder="Fonction"
                        value={employeeData.duty}
                        onChange={handleChange}
                    />
                    {errors.duty && <div className="error">{errors.duty}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="nationality">Nationalité:</Label>
                    <Input
                        type="text"
                        id="nationalty"
                        name="nationalty"
                        placeholder="Nationalité"
                        value={employeeData.nationalty}
                        onChange={handleChange}
                    />
                    {errors.nationalty && <div className="error">{errors.nationalty}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="address">Adresse:</Label>
                    <Input
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Adresse"
                        value={employeeData.address}
                        onChange={handleChange}
                    />
                    {errors.address && <div className="error">{errors.address}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="socialSecurityNumber">Numéro de Sécurité Sociale:</Label>
                    <Input
                        type="text"
                        id="socialSecurityNumber"
                        name="socialSecurityNumber"
                        placeholder="Numéro de Sécurité Sociale"
                        value={employeeData.socialSecurityNumber}
                        onChange={handleChange}
                    />
                    {errors.socialSecurityNumber && <div className="error">{errors.socialSecurityNumber}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="dateDebutConsultant">Date de début consultant:</Label>
                    <Input
                        type="date"
                        id="dateDebutConsultant"
                        name="dateDebutConsultant"
                        value={employeeData.dateDebutConsultant}
                        onChange={handleChange}
                    />
                    {errors.dateDebutConsultant && <div className="error">{errors.dateDebutConsultant}</div>}
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Annuler
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Modifier
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditEmployeeModal;
