import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import './Header.module.scss'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'; // Import de l'icône Material-UI

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {decodeJWT, logoutUser} from "../../actions/auth";
import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon";
import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";

import s from "./Header.module.scss";
import "animate.css";
import {getCurrentInfo, getOrganizations} from "../../services/configService";
import {useHistory} from "react-router-dom";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [firstName, setFirstName]= useState("prenom");
  const [lastName,setLastName]= useState("nom");
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const history = useHistory();

  const handleProfileClick = () => {
    history.push("/home/Profile");
  };
  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  }

  const doLogout = () => {
    localStorage.clear(); // Supprime tout du localStorage
    history.push("/login"); // Redirige vers la page de connexion
  };
  const isConsultant = (decodeJWT(localStorage.getItem("token")).role)==="consultant";
  const role = decodeJWT(localStorage.getItem("token")).role
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const selectOrganization = (organization) => {
    setSelectedOrganization(organization);
  }

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getOrganizations();
        setOrganizations(res.data);
        const res2 = await getCurrentInfo();
        setFirstName(res2.data.name)
        setLastName(res2.data.surname)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <Navbar className={`${s.root} d-print-none`}>
      <Nav >
        {/** {!isConsultant && <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="ml-3 compagny-selector" >
          <DropdownToggle caret>
            {selectedOrganization ? selectedOrganization.name : 'Select Organization'}
          </DropdownToggle>
           <DropdownMenu style={{ left: 0 }}>
            {organizations.map(organization => (
              <DropdownItem key={organization.id} onClick={() => selectOrganization(organization)}>
                <i className="eva eva-home-outline company-logo" />
                <p className="body-1 mb-0">{organization.name}</p>
                <p className="body-3 text-muted mb-0">{organization.adressLine1}</p>
                {organization.city}
                <p className="body-3 text-muted mb-0">{organization.country}</p>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>}*/}
      </Nav>
      <Nav className="ml-auto">
        <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown" className="ml-3">
          <DropdownToggle nav caret className="navbar-dropdown-toggle">
            <span className="d-none d-sm-block ml-1 mr-2 body-1 name-icon-container">
              <PersonOutlineIcon className="icon" />
              <span className="Header-Name mr-2">{lastName}</span>
              <span className="Header-Name">{firstName}</span>
            </span>
          </DropdownToggle>
          <DropdownMenu className="navbar-dropdown profile-dropdown" style={{width: "194px"}}>
            <DropdownItem className={s.dropdownProfileItem} onClick={handleProfileClick} ><ProfileIcon/><span>Profile</span></DropdownItem>
            <DropdownItem className={s.dropdownProfileItem}><TasksIcon /><span>Tasks</span></DropdownItem>
            <DropdownItem className={s.dropdownProfileItem}><MessagesIcon /><span>Messages</span></DropdownItem>
            <NavItem>
              <NavLink onClick={() => doLogout()} href="#">
                <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img src={logoutIcon} alt="Logout" /><span className="ml-1">Logout</span></button>
              </NavLink>
            </NavItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

