import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Form,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Widget from "../../components/Widget/Widget";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import {
    getClients,
    getLegalstatusAll,
    saveCompagny,
    updateCompany,
    deleteFournisseurs,
} from "../../services/configService";
import showToast from "../../components/NotificationToast/toastUtils";

const Clients = () => {
    const dispatch = useDispatch();

    const [clients, setClients] = useState([]);
    const [legalStatusOptions, setLegalStatusOptions] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [modalState, setModalState] = useState({
        add: false,
        edit: false,
        delete: false,
        view: false,
    });
    const [formData, setFormData] = useState({
        name: "",
        legalStatus: "",
        siretNumber: "",
        tvaNumber: "",
        registrationDate: "",
        socialCapital: "",
        logo: "",
        website: "",
        address: "",
        subaddress: "",
        type: ["customer"],
    });
    const [loading, setLoading] = useState(false); // State for loading

    const columns = [
        { dataField: "name", text: "Nom", sort: true },
        { dataField: "siretNumber", text: "Siret" },
        { dataField: "socialCapital", text: "Social Capital" },
        { dataField: "tvaNumber", text: "TVA Number" },
        {
            dataField: "registrationDate",
            text: "Date d'enregistrement",
            formatter: (cell) => new Date(cell).toLocaleDateString("fr-FR"),
        },
    ];

    const fetchClients = async () => {
        dispatch(startLoading());
        try {
            const res = await getClients();
            setClients(res.data);
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(stopLoading());
        }
    };

    const fetchLegalStatusOptions = async () => {
        try {
            const res = await getLegalstatusAll();
            setLegalStatusOptions(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchClients();
        fetchLegalStatusOptions();
    }, [dispatch]);

    const toggleModal = (modal) => {
        setModalState((prev) => ({ ...prev, [modal]: !prev[modal] }));
        if (modal !== "edit") resetFormData(); // Reset form data when not editing
    };

    const resetFormData = () => {
        setFormData({
            name: "",
            legalStatus: "",
            siretNumber: "",
            tvaNumber: "",
            registrationDate: "",
            socialCapital: "",
            logo: "",
            website: "",
            address: "",
            subaddress: "",
            type: ["customer"],
        });
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handleAddClient = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            await saveCompagny(formData);
            showToast("Client ajouté avec succès.");
            toggleModal("add");
            fetchClients();
        } catch (error) {
            const message = error.response?.data?.message || "Erreur lors de l'ajout du client.";
            showToast(message, "error");
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleEditClient = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        const updatedFormData = {
            ...formData,
            type: selectedClient.type || ["customer"], // Assurez-vous que le type est défini
        }
        try {
            await updateCompany(selectedClient.id, updatedFormData);
            showToast("Client modifié avec succès.");
            toggleModal("edit");
            fetchClients();
        } catch (error) {
            const message = error.response?.data?.message || "Erreur lors de la modification du client.";
            showToast(message, "error");
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleDeleteClients = async () => {
        const ids = selectedRows.map((row) => row.id);
        try {
            await deleteFournisseurs(ids);
            showToast("Clients supprimés avec succès.");
            toggleModal("delete");
            setSelectedRows([]);
            fetchClients();
        } catch (error) {
            showToast("Erreur lors de la suppression des clients.", "error");
        }
    };

    const handleRowClick = (row) => {
        setSelectedClient(row); // Set the selected client data
        setFormData(row); // Populate the form with the selected client data
        toggleModal("edit"); // Open the edit modal
    };

    const handleSelectRow = (row, isSelect) => {
        setSelectedRows((prev) =>
            isSelect ? [...prev, row] : prev.filter((r) => r.id !== row.id)
        );
    };
    return (
        <Row className="mb-4">
            <Col>
                <Widget>
                    <div className="button-container mb-1 mt-2 mr-2">
                        <Button color="primary" className="mr-2" onClick={() => toggleModal("add")}>
                            <i className="eva eva-plus-outline icon" />
                        </Button>
                        {selectedRows.length === 1 && (
                            <Button color="primary" className="mr-2" onClick={() => handleRowClick(selectedRows[0])}>
                                <i className="eva eva-edit-outline icon" />
                            </Button>
                        )}
                        {selectedRows.length > 0 && (
                            <Button color="danger" className="mr-2" onClick={() => toggleModal("delete")}>
                                <i className="eva eva-trash-2-outline icon" />
                            </Button>
                        )}
                    </div>
                    <BootstrapTable
                        keyField="id"
                        data={clients}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true })}
                        selectRow={{
                            mode: "checkbox",
                            onSelect: handleSelectRow,
                            onSelectAll: (isSelect, rows) =>
                                setSelectedRows(isSelect ? rows : []),
                        }}
                        hover
                        striped
                        condensed
                        rowEvents={{
                            onClick: (e, row) => handleRowClick(row),  // Handle row click for editing
                        }}
                    />
                </Widget>
            </Col>

            {/* Add Client Modal */}
            <Modal isOpen={modalState.add} toggle={() => toggleModal("add")}>
                <ModalHeader toggle={() => toggleModal("add")}>
                    Ajouter un Client
                </ModalHeader>
                <Form onSubmit={handleAddClient}>
                    <ModalBody>
                        {/** Form fields... **/}
                        <FormGroup>
                            <Label for="name">Nom</Label>
                            <Input
                                id="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Nom du client"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="legalStatus">Statut juridique</Label>
                            <Input
                                type="select"
                                id="legalStatus"
                                value={formData.legalStatus}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Sélectionner...</option>
                                {legalStatusOptions.map(option => (
                                    <option key={option.id} value={option.id}>{option.codeLabel} - {option.label}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="siretNumber">Siret</Label>
                            <Input
                                id="siretNumber"
                                value={formData.siretNumber}
                                onChange={handleInputChange}
                                placeholder="Numéro Siret"
                                type="number"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tvaNumber">TVA</Label>
                            <Input
                                id="tvaNumber"
                                value={formData.tvaNumber}
                                onChange={handleInputChange}
                                placeholder="Numéro TVA"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="registrationDate">Date d'enregistrement</Label>
                            <Input
                                id="registrationDate"
                                type="date"
                                value={formData.registrationDate}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="socialCapital">Capital Social</Label>
                            <Input
                                id="socialCapital"
                                value={formData.socialCapital}
                                onChange={handleInputChange}
                                placeholder="Capital Social"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="logo">Logo (URL)</Label>
                            <Input
                                id="logo"
                                value={formData.logo}
                                onChange={handleInputChange}
                                placeholder="URL du logo"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="website">Site Web</Label>
                            <Input
                                id="website"
                                value={formData.website}
                                onChange={handleInputChange}
                                placeholder="Site Web"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="address">Adresse</Label>
                            <Input
                                id="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                placeholder="Adresse complète"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="subaddress">Sous-adresse</Label>
                            <Input
                                id="subaddress"
                                value={formData.subaddress}
                                onChange={handleInputChange}
                                placeholder="Sous-adresse (Complément d'adresse)"
                                required
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={loading}>
                            {loading ? "Chargement..." : "Ajouter"}
                        </Button>
                        <Button color="secondary" onClick={() => toggleModal("add")}>
                            Annuler
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Edit Client Modal */}
            <Modal isOpen={modalState.edit} toggle={() => toggleModal("edit")}>
                <ModalHeader toggle={() => toggleModal("edit")}>
                    Modifier le Client
                </ModalHeader>
                <Form onSubmit={handleEditClient}>
                    <ModalBody>
                        {/* Same fields as in Add Modal */}
                        <FormGroup>
                            <Label for="name">Nom</Label>
                            <Input
                                id="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Nom du client"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="legalStatus">Statut juridique</Label>
                            <Input
                                type="select"
                                id="legalStatus"
                                value={formData.legalStatus}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Sélectionner...</option>
                                {legalStatusOptions.map(option => (
                                    <option key={option.id} value={option.id}>{option.codeLabel} - {option.label}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="siretNumber">Siret</Label>
                            <Input
                                id="siretNumber"
                                value={formData.siretNumber}
                                onChange={handleInputChange}
                                placeholder="Numéro Siret"
                                type="number"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tvaNumber">TVA</Label>
                            <Input
                                id="tvaNumber"
                                value={formData.tvaNumber}
                                onChange={handleInputChange}
                                placeholder="Numéro TVA"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="registrationDate">Date d'enregistrement</Label>
                            <Input
                                id="registrationDate"
                                type="date"
                                value={formData.registrationDate}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="socialCapital">Capital Social</Label>
                            <Input
                                id="socialCapital"
                                value={formData.socialCapital}
                                onChange={handleInputChange}
                                placeholder="Capital Social"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="logo">Logo (URL)</Label>
                            <Input
                                id="logo"
                                value={formData.logo}
                                onChange={handleInputChange}
                                placeholder="URL du logo"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="website">Site Web</Label>
                            <Input
                                id="website"
                                value={formData.website}
                                onChange={handleInputChange}
                                placeholder="Site Web"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="address">Adresse</Label>
                            <Input
                                id="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                placeholder="Adresse complète"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="subaddress">Sous-adresse</Label>
                            <Input
                                id="subaddress"
                                value={formData.subaddress}
                                onChange={handleInputChange}
                                placeholder="Sous-adresse (Complément d'adresse)"
                                required
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={loading}>
                            {loading ? "Chargement..." : "Modifier"}
                        </Button>
                        <Button color="secondary" onClick={() => toggleModal("edit")}>
                            Annuler
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Delete Client Modal */}
            <Modal isOpen={modalState.delete} toggle={() => toggleModal("delete")}>
                <ModalHeader toggle={() => toggleModal("delete")}>
                    Supprimer des Clients
                </ModalHeader>
                <ModalBody>
                    Êtes-vous sûr de vouloir supprimer {selectedRows.length} clients ?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDeleteClients}>
                        Supprimer
                    </Button>
                    <Button color="secondary" onClick={() => toggleModal("delete")}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </Row>
    );
};

export default Clients;
