import React, { useState, useEffect } from "react";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import {generateFactures, getFacture, validateFactures} from "../../services/configService";
import showToast from "../../components/NotificationToast/toastUtils";
import Widget from "../../components/Widget/Widget";

const FacturePage = () => {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [invoices, setInvoices] = useState([]);

    const statusFormatter = (cell) => {
        let badgeColor;
        let label;
        switch (cell) {
            case 4:
                badgeColor = "info";
                label = "émise";
                break;
            case 5:
                badgeColor = "warning";
                label = "comptabilisée";
                break;
            default:
                badgeColor = "secondary";
                label = "Pas statut";
        }
        return <span className={`badge bg-${badgeColor}`}>{label}</span>;
    };

    const columns = [
        { dataField: "consultant", text: "Consultant", sort: true },
        { dataField: "client", text: "Client", sort: true },
        { dataField: "montantHT", text: "Montant HT (€)", sort: true },
        {
            dataField: "tvaP", text: "TVA (%)", sort: true, formatter: (cell) => {
                return `${(cell * 100).toFixed(2)}%`;
            },
        },
        { dataField: "montontTva", text: "Montant TVA (€)", sort: true },
        { dataField: "montontTTc", text: "Montant TTC (€)", sort: true },
        {
            dataField: "dateFacture",
            text: "Date Facture",
            sort: true,
            formatter: (cell) => {
                const date = new Date(cell);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        { dataField: "status", text: "Statut", sort: true, formatter: statusFormatter }
    ];

    const fetchInvoices = async () => {
        dispatch(startLoading());
        setSelectedInvoiceIds([]);
        try {
            const response = await getFacture(month, year);
            setInvoices(response.data || []);
        } catch (error) {
            showToast("Erreur lors de la récupération des factures", "error");
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, [month, year, dispatch]);

    const handleGenerateFactures = () => {
        if (selectedInvoiceIds.length > 0) {
            generateFactures(selectedInvoiceIds)
                .then((response) => {
                    const blob = response.data;
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'factures.zip';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    showToast("Erreur lors de la génération des factures", "error");
                });
        } else {
            showToast("Veuillez sélectionner des factures.", "error");
        }
    };
    const handleValidateFactures= () => {
        if (selectedInvoiceIds.length > 0) {
            validateFactures(selectedInvoiceIds)
                .then((response) => {
                    showToast("Les factures sont comptabilsées");
                }).catch((error) => {
                showToast("Erreur lors de la validation des factures", "error");
            }) .finally(() => {
                fetchInvoices();
            });
        }
    }

    return (
        <div>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <Label>Mois</Label>
                        <Input
                            type="select"
                            value={month}
                            onChange={(e) => setMonth(Number(e.target.value))}
                        >
                            {Array.from({ length: 12 }, (_, i) => (
                                <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Année</Label>
                        <Input
                            type="select"
                            value={year}
                            onChange={(e) => setYear(Number(e.target.value))}
                        >
                            {Array.from({ length: 10 }, (_, i) => 2024 + i).map((y) => (
                                <option key={y} value={y}>
                                    {y}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col className="d-flex justify-content-end align-items-end">
                    <Button onClick={handleGenerateFactures} className="mr-3">
                        Télécharger
                    </Button>
                    <Button onClick={handleValidateFactures}>
                        Comptabiliser
                    </Button>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <Widget>
                        <BootstrapTable
                            keyField="id"
                            data={invoices}
                            columns={columns}
                            pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true })}
                            hover
                            striped
                            condensed
                            selectRow={{
                                mode: "checkbox",
                                clickToSelect: true,
                                selected: selectedInvoiceIds,
                                onSelect: (row, isSelected) => {
                                    setSelectedInvoiceIds((prev) =>
                                        isSelected
                                            ? [...prev, row.id]
                                            : prev.filter((id) => id !== row.id)
                                    );
                                },
                                onSelectAll: (isSelected, rows) => {
                                    const ids = rows.map((row) => row.id);
                                    setSelectedInvoiceIds(isSelected ? ids : []);
                                },
                            }}
                        />
                    </Widget>
                </Col>
            </Row>
        </div>
    );
};

export default FacturePage;
